import { useState } from 'react';
import '../styles/Farewell.css';

const Farewell: React.FC = () => {
	const [mamiGone, setMamiGone]: [Boolean, Function] = useState(false);

	return mamiGone ? (
		<div className='farewell-container'>
			<p>
				Reader, thank you for being in my life, and if you haven’t,
				then thank you for being here in my passing.
			</p>

			<p>
				I didn’t want to publish this, because it would mean I came
				to terms with my potential demise.
			</p>

			<p>
				I’m not sure how long is left before this domain expires and
				this message fades into the void, so feel free to back it up
				and forward it to anyone who may have missed it and you
				believe needs to see it.
			</p>

			<p>
				Reader, whether you were my friend, or someone who I’ve yet
				to befriend, and whether you believe I’m a good person, or
				think that I’m an evil mastermind, I hope that you at least
				can appreciate the price of a life, in this case, my life.
			</p>

			<p>
				I didn’t expect to die this early in my life, I always
				thought that I’d live to old age and be that one cartoony
				old man chilling on his lawn complaining about the younger
				generation.
			</p>

			<p>Heck, I already do the latter.</p>

			<p>
				I had a lot to live for and a lot of plans for the future,
				and it saddens me I won’t get to accomplish those goals.
				Please, if you could, continue my legacy, be nice to others,
				even if they don’t always align with you, there’s a charm in
				kindness that cannot be found elsewhere. I learnt to
				appreciate that, and I hope you will too.
			</p>

			<p>
				I’m glad that I got to know so many wonderful people
				throughout my life, from youth to adulthood, thank you for
				being in my life.
			</p>

			<p>
				I’m aware that not everyone who’s reading this is my friend,
				maybe you’re a stranger.
			</p>

			<p>
				If you’re a stranger, then I wish I had gotten to meet you,
				we could’ve been great friends, I’m certain. But maybe you
				consider me to be your enemy, in which case, I’m sorry that
				you feel that way.
			</p>

			<p>
				I can’t stop you from disliking me, hate is something that
				is quick to get but slow to erase. That is something I
				learnt through my early teenage years, and while I may have
				lost a lot of great relationships due to my ignorance, it
				helped me to better preserve my later relationships.
			</p>

			<p>
				As a child I always believed I could befriend anyone, given
				enough time, but I was sorely mistaken.
			</p>

			<p>
				While yes, in a perfect world where I had infinite time to
				spend on each and every individual who I set the path upon
				to befriend, I could, as I grew up, I realised I don’t live
				in such reality.
			</p>

			<p>
				Time is too limited and some people will discard any attempt
				I make simply for my being an Israeli, for example. They
				won’t care that I have so much more to offer, as an
				individual.
			</p>

			<p>
				Yet, you’re here, and that means you care, whether you’re
				sad that I’m gone, or glad that you don’t have to worry
				about me anymore, you still care enough to come here and
				read this, so thank you.
			</p>

			<p>
				As I was writing this the situation in Israel is worsening,
				Iran began attacking us as well.
			</p>

			<p>
				I never kept up to date with the ongoing war, so I don’t
				really understand what is happening very well.
			</p>

			<p>
				But what I can say is that the explosions are getting nearer
				and nearer and that I feel the ground shake beneath my feet.
			</p>

			<p>
				But why write this? People kept telling me that I’d be fine
				and that there’s nothing to worry about. Well, I live in a
				way where I always hope for the best, but make sure to
				prepare for the worst.
			</p>

			<p>
				Maybe I’ll live to see world peace, or maybe I’ll die in the
				hands of a terrorist, the uncertainty is why this document
				exists. Nothing is certain, and nothing is promised.
			</p>

			<p>
				Even the deepest of promises is bound to expire if the
				universe converges to that reality.
			</p>

			<p>
				While I live in a relatively safe area, there’s no promises
				it will remain safe for long. It only takes 1 piece of
				debris to cut a life short, and who’s to say it won’t be
				mine?
			</p>

			<p>
				In any case, my assumption is that a regional war is
				nearing, and if the US gets involved (which it most likely
				will), then we’re potentially sparking WWIII.
			</p>

			<p>
				I can’t afford to flee, and neither do I want to. Whether I
				like where I live or not, I was born here, I started a life
				here, and I don’t think it’d be fair to me as an individual
				to be removed for something that started long before I was
				even an idea.
			</p>

			<p>
				But the universe doesn’t play fair, it doesn’t care what I
				want or need, it doesn’t work for anyone, and in the grand
				scheme of things, my existence is null to the universe.
			</p>

			<p>That was quite grim…</p>

			<p>
				Hey there! I love you! I love all of you, I really do, and
				it doesn’t matter who you are.
			</p>

			<p>
				I always struggled to express my love because I feared my
				love could be misunderstood, especially in the e-dating
				phase of the internet, it’s a scary world out there.
			</p>

			<p>
				My love knows no bounds and no limits, it is infinite and
				unlimited, it is pure and unconditional.
			</p>

			<p>
				It is due to the above that I can share this document with
				the world. I love you, world, with all the hatred you have
				for me, I love that too, I can’t despise the world that let
				me be, and I can’t complain that the world who let me be
				decided it was time to go.
			</p>

			<p>
				So, know that even in death, my love will live on. You are
				my love.
			</p>

			<p>Thank you for reading, with unwavering love,</p>

			<p>- Mami Tomoe. (get goofed, and good bye!)</p>
		</div>
	) : (
		<div className='farewell-container'>
			<p>
				Hello! Thank you for taking the time to be here and read
				this. My name is Mami Tomoe (formerly known as Se7en).
			</p>
			<p>
				I wrote this just in case something happens to me… you know,
				in case I don't make it. But hey, just because you're
				reading this online doesn't mean I'm dead! Although, if I’ve
				been gone for a long time, well... this might be relevant.
			</p>
			<p>
				That’s why some of this is written as though I might have
				passed away—better safe than sorry. However, that may not be
				true! Maybe I’m only hurt, or maybe you’re reading this out
				of context.
			</p>
			<p>
				So, it’s probably best not to continue reading unless you're
				fairly certain something is wrong, like if I’ve been gone
				for a month or longer.
			</p>
			<p>To read, press this button:</p>
			<button onClick={() => setMamiGone(true)}>
				I Believe Mami Is Gone
			</button>
		</div>
	);
};

export default Farewell;

import logo from './logo.png';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Farewell from './components/Farewell';

function App() {
	return (
		<Router>
			<div className='App'>
				<header className='App-header'>
					<Link
						className='App-header-link'
						to='/'
						rel='noopener noreferrer'>
						{' '}
						<img
							src={logo}
							className='App-logo'
							alt='logo'
						/>
						<p>Tiro Finale</p>
					</Link>
				</header>
			</div>

			<Routes>
				<Route
					path='/'
					element={<Home />}
				/>
				<Route
					path='/Farewell'
					element={<Farewell />}
				/>
			</Routes>
		</Router>
	);
}

export default App;

import React from 'react';
import { BlogPost } from '../types/BlogPost';
import '../styles/ViewBlog.css'; // Add the CSS file for styling

const ViewBlog: React.FC<BlogPost> = ({
	title,
	description,
	date,
	url,
	external,
}) => {
	return (
		<div className='view-blog-container'>
			{/* Display the title */}
			<h1 className='blog-title'>{title}</h1>

			{/* Display the date */}
			<p className='blog-date'>{date}</p>

			{/* Display the description */}
			<p className='blog-description'>{description}</p>

			{/* Display the link
			<div className='blog-link-container'>
				<a
					href={url}
					target={external ? '_blank' : '_self'}
					rel='noopener noreferrer'
					className='blog-link'>
					{external ? 'Visit Website' : 'Read More'}
				</a>
			</div> */}
		</div>
	);
};

export default ViewBlog;

import React, { useState } from 'react';
import '../styles/WebsiteCard.css'; // Import the CSS file
import ViewBlog from './ViewBlog'; // Import the ViewBlog component

type WebsiteCardProps = {
	title: string;
	description: string;
	date: string;
	url: string;
	external: boolean;
};

const WebsiteCard: React.FC<WebsiteCardProps> = ({
	title,
	description,
	date,
	url,
	external,
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const [showBlogPost, setShowBlogPost] = useState(false); // Manage state to show ViewBlog

	// Handle showing blog post on card click
	const handleCardClick = () => {
		setShowBlogPost(!showBlogPost); // If no URL, show the ViewBlog component
	};

	let shortDescription = description.substring(0, 64);

	if (shortDescription !== description)
		shortDescription = `${shortDescription}...`;

	return (
		<div className='card-container'>
			<a
				className='card-link'
				href={url === '' ? undefined : url}
				target={external ? '_blank' : '_self'}
				rel='noopener noreferrer'
				onClick={(e) => {
					if (url === '') {
						e.preventDefault(); // Prevent default link action
						handleCardClick(); // Trigger ViewBlog component display
					}
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}>
				<div className={`card ${isHovered ? 'hover' : ''}`}>
					{/* External Icon */}
					{external && <div className='external-icon'>↗</div>}

					<h3>{title}</h3>
					<p>{shortDescription}</p>

					{/* Date at the bottom right */}
					<div className='card-date'>{date}</div>
				</div>
			</a>

			{/* Conditionally render the ViewBlog component when clicked */}
			{showBlogPost && (
				<ViewBlog
					title={title}
					description={description}
					date={date}
					url={url}
					external={external}
				/>
			)}
		</div>
	);
};

export default WebsiteCard;

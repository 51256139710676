import WebsiteCard from './WebsiteCard';
import blogPosts from '../data/blogPosts.json';
import { BlogPost } from '../types/BlogPost';

const Home: React.FC = () => {
	return (
		<div style={{ padding: '20px' }}>
			{blogPosts.map((x: BlogPost, i: Number) => {
				return (
					<div key={`blog_post-${i}`}>
						<WebsiteCard
							title={x.title}
							description={x.description}
							date={x.date}
							url={x.url}
							external={x.external}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default Home;
